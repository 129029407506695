<script>
import { mapGetters } from 'vuex'
import IconClose from '../assets/icon-close.vue'

import { DASHBOARD, UPDATE_CA, UPLOAD_FILE } from '../store/actions'

export default {
  name: 'EditProfileModal',
  components: {
    IconClose,
  },
  data() {
    return {
      sending: false,
      urlPhoto: null,
    }
  },
  computed: {
    ...mapGetters(['isMaster', 'photoURL', 'cid', 'photoHandle']),
    caName: {
      get() {
        return this.$store.state.caName
      },
      set(newValue) {
        this.$store.commit('setCaName', newValue)
      },
    },
    isDisabled() {
      return this.sending
      // return false;
    },
  },
  watch: {},

  mounted() {
    const el = document.getElementById('fileUpload')
    if (el) {
      el.addEventListener('change', (event) => {
        this.photoFile = event.target.files[0]
        this.urlPhoto = URL.createObjectURL(this.photoFile)
      })
    }
  },
  methods: {
    chooseFiles() {
      document.getElementById('fileUpload').click()
    },
    close() {
      this.$emit('close')
    },
    uploadPhoto(file, folder) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(UPLOAD_FILE, { file, folder })
          .then((url) => {
            resolve(url)
          })
          .catch((err) => {
            this.logError('uploadPhotos', err)
            reject(err)
          })
      })
    },
    updateCA(photoURL) {
      this.$store
        .dispatch(UPDATE_CA, { name: this.caName, img_url: photoURL })
        .then((cid) => {
          if (cid) {
            this.$store.dispatch(DASHBOARD).then(() => this.close())
          }
        })
        .catch((err) => {
          console.error(err)
          this.sending = false
        })
    },
    save() {
      this.sending = true
      if (this.photoFile) {
        this.uploadPhoto(this.photoFile).then((photoURL) => {
          this.updateCA(photoURL)
        })
      }
      else {
        this.updateCA(this.photoURL)
      }
    },
  },

}
</script>

<template>
  <transition name="modal-fade">
    <div v-show="true" class="modal-backdrop">
      <div
        class="modal-wallid edit-profile-modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        @click.stop
      >
        <header id="modalTitle" class="modal-header">
          <slot name="header">
            {{ $t('editProfile.title') }}
            <v-spacer />
            <button
              type="button"
              class="btn-close"
              aria-label="Close modal"
              @click="close"
            >
              <IconClose />
            </button>
          </slot>
        </header>

        <div class="modal-body text-center pb-9">
          <slot name="body">
            <v-container id="scroll" class="pb-16 scroll">
              <v-row>
                <v-col
                  id="photo-container"
                  style="justify-content: center"
                  cols="4"
                  md="3"
                  class="pb-2"
                >
                  <div class="wrapper">
                    <div
                      class="company-header-avatar"
                      :style="
                        `background-image: url(${urlPhoto || photoURL})`
                      "
                    />
                    <!-- <v-img
                            v-if="!photoURL"
                            class="mx-auto"
                            max-height="145"
                            max-width="145"
                            :src="photoURL"
                          /> -->
                    <ImageProfile v-if="!(photoURL || urlPhoto)" />
                  </div>
                </v-col>
                <v-col
                  id="errorUrlPhoto"
                  cols="8"
                  md="8"
                  class="input-field pb-2 text-left"
                >
                  <p class="small_text">
                    {{ $t('createCertModal.upload[0]') }}
                  </p>
                  <p class="mb-0 small_text">
                    {{ $t('createCertModal.upload[1]') }}
                  </p>
                  <p class="small_text">
                    {{ $t('createCertModal.upload[2]') }}
                  </p>
                  <input
                    id="fileUpload"
                    type="file"
                    hidden
                    accept="image/x-png,image/gif,image/jpeg"
                  >
                  <v-btn class="back" @click="chooseFiles()">
                    {{ $t('createCertModal.uploadButton') }}
                  </v-btn>
                </v-col>
                <v-col cols="12" md="12" class="input-field pb-2 text-left">
                  <label class="">{{ $t('createCAModal.caName') }}</label>
                  <v-text-field
                    v-model="caName"
                    class=""
                    flat
                    solo
                    persistent-hint
                    :hint="$t('createCAModal.hint')"
                  />
                </v-col>
              </v-row>
            </v-container>
          </slot>
        </div>

        <div class="modal-footer">
          <slot name="footer">
            <v-row class="justify-end" no-gutters>
              <v-btn
                class="next"
                :loading="isDisabled"
                :disabled="isDisabled"
                @click="save()"
              >
                {{ $t('editProfile.save') }}
              </v-btn>
            </v-row>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
.modal-wallid.edit-profile-modal {
  position: relative;
  .v-stepper {
    box-shadow: none;
  }
  .modal-footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
  .container.scroll {
    max-height: 420px;
    overflow: auto;
  }
  .static_image {
    .v-image {
      position: absolute;
    }
  }
  .input-field {
    .v-input__control {
      min-height: unset;
      .v-input__slot {
        margin-bottom: 4px;
        height: 44px;
        border-radius: 3px;
        border: solid 1px var(--light-grey-blue);
      }
      .v-text-field__details {
        margin-bottom: 4px;
      }
    }
  }
}
</style>
