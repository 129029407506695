<script>
import { mapGetters } from 'vuex'
import IconTemplatesCredentials from '../assets/icon-templates-created'
import IconIssuedCredentials from '../assets/icon-issued-credentials.vue'
import IconExternalLink from '../assets/icon-external-link.vue'
import IconEdit from '../assets/icon-edit.vue'

import AdminTable from '../components/AdminTable'
import IconMenu from '../assets/icon-dot-menu'

import EditProfileModal from '../modals/EditProfileModal'
// import { PROFILE } from "../store/actions";

import emptyTablePhoto from '../assets/no-pending-tasks.png'

export default {
  name: 'Profile',
  components: {
    AdminTable,
    IconMenu,
    IconTemplatesCredentials,
    IconIssuedCredentials,
    EditProfileModal,
    IconEdit,
    IconExternalLink,
  },
  data() {
    return {
      customTemplate: { 'wallid.io': true },
      showEditProfileModal: false,
      issuedCounter: 0,
      tab: 0,
      search: null,
      managersTable: [],
      templatesTable: [],
      emptyTablePhoto,
    }
  },
  computed: {
    ...mapGetters([
      'isMaster',
      'caName',
      'allowedTemplates',
      'allowedCredentials',
      'allApprovedCounter',
      'allAdminsTable',
      'mastersTable',
      'photoURL',
      'templates',
      'caAddress',
      'adminEmail',
    ]),
    hasCredentials() {
      return (
        !this.$isProduction
        || this.totalTemplatesCounter < this.allowedTemplates
      ) // return true for testing
    },
    isCustomTemplate() {
      return (
        this.adminEmail
        && this.customTemplate[this.getEmail(this.adminEmail).domain]
      )
    },
    totalTemplatesCounter() {
      return this.$store.state.templates.length
    },
    templatesHeaders() {
      const headers = {
        en: [
          {
            text: 'Name',
            align: 'start',
            value: 'name',
            sortable: false,
          },
          {
            text: 'Attributes',
            value: 'attributes',
            sortable: false,
          },
          {
            text: 'Public Attribute',
            value: 'public_field',
            width: 150,
            sortable: false,
          },
        ],
        pt: [
          {
            text: 'Nome',
            align: 'start',
            value: 'name',
            sortable: false,
          },
          {
            text: 'Atributos',
            value: 'attributes',
            sortable: false,
          },
          {
            text: 'Atributo público',
            value: 'public_field',
            width: 150,
            sortable: false,
          },
        ],
        es: [
          {
            text: 'Nombre',
            align: 'start',
            value: 'name',
            sortable: false,
          },
          {
            text: 'Atributos',
            value: 'attributes',
            sortable: false,
          },
          {
            text: 'Atributo público',
            value: 'public_field',
            width: 150,
            sortable: false,
          },
        ],
      }
      if (this.isMaster) {
        headers.en.push({
          text: 'Actions',
          value: 'actions',
          sortable: false,
          filterable: false,
          width: 50,
        })
        headers.pt.push({
          text: 'Actions',
          value: 'actions',
          sortable: false,
          filterable: false,
          width: 50,
        })
      }
      return headers
    },
    adminHeaders() {
      const headers = {
        en: [
          {
            text: 'Name',
            align: 'start',
            value: 'name',
          },
          {
            text: 'Role',
            value: 'role',
          },
          {
            text: 'Email',
            value: 'email',
            width: this.isMaster ? 250 : 300,
          },
        ],
        pt: [
          {
            text: 'Nome',
            align: 'start',
            value: 'name',
          },
          {
            text: 'Nível',
            value: 'role',
          },
          {
            text: 'Email',
            value: 'email',
            width: this.isMaster ? 250 : 300,
          },
        ],
        es: [
          {
            text: 'Nombre',
            align: 'start',
            value: 'name',
          },
          {
            text: 'Nível',
            value: 'role',
          },
          {
            text: 'Email',
            value: 'email',
            width: this.isMaster ? 250 : 300,
          },
        ],
      }

      if (this.isMaster) {
        headers.en.push({
          text: 'Status',
          value: 'status',
        })
        headers.pt.push({
          text: 'Estados',
          value: 'status',
        })
        headers.es.push({
          text: 'Estados',
          value: 'status',
        })
        headers.en.push({
          text: 'Actions',
          value: 'actions',
          sortable: false,
          filterable: false,
          width: 50,
        })
        headers.pt.push({
          text: 'Acções',
          value: 'actions',
          sortable: false,
          filterable: false,
          width: 50,
        })
        headers.es.push({
          text: 'Acciónes',
          value: 'actions',
          sortable: false,
          filterable: false,
          width: 50,
        })
      }
      return headers
    },
  },

  watch: {
    templates(value) {
      console.log('new value', value.length)
      if (this.templatesTable.length === 0) {
        value.forEach((e) => {
          this.templatesTable.push({
            name: e.name,
            attributes: this.getAttributes(e.templateItens),
            public_field: this.getPublicField(e.templateItens),
            cid: e.cid,
          })
        })
      }
    },
  },

  created() {
    this.debug('*** ISSUER PROFILE *** ')
  },
  mounted() {
    this.debug('Templates', this.templates.length)
    this.templates.forEach((e) => {
      this.templatesTable.push({
        name: e.name,
        attributes: this.getAttributes(e.templateItens),
        public_field: this.getPublicField(e.templateItens),
        cid: e.cid,
      })
    })
    // if (this.allAdminsTable.length == 0) this.$store.dispatch(PROFILE);
  },
  methods: {
    /**
     * pushes to router ViewTemplate route with the @template tid
     */
    openCredential(template) {
      this.debug('template tid: ', template.tid)
      if (template.tid) {
        this.$router.push({
          name: 'ViewTemplateIndex',
          query: { tid: template.tid },
        })
      }
    },
    getAttributes(l = []) {
      return l.map(e => ` ${e.attr}`)
    },
    getPublicField(l = []) {
      return (l.find(e => e.isPublic) || { attr: '' }).attr
    },
    addNewAdmin() {
      this.$log.debug('addNewAdmin')
    },
    addNewTemplate() {
      this.$router.push({
        path: `CredentialDesign`,
      })
    },
    deleteTemplate(item) {
      this.$log.debug('deleteTemplate', item)
    },
  },
}
</script>

<template>
  <v-container class="issuer-profile">
    <v-row>
      <v-col cols="12" class="pt-15">
        <h1 class="title_header">
          {{ caName }} {{ $t('profile.title') }}
        </h1>
      </v-col>
      <v-col cols="12" md="9" class="pb-8 pt-4 pr-md-16">
        <v-row class="">
          <v-col cols="12" class="pt-1 pb-2">
            <v-card class="info-card">
              <v-container>
                <v-row class="py-5 pl-7">
                  <v-col
                    id="photo-container"
                    style="justify-content: center"
                    cols="4"
                    md="3"
                    class="pb-2"
                  >
                    <div class="wrapper">
                      <div
                        class="company-header-avatar"
                        :style="`background-image: url(${photoURL})`"
                      />
                      <v-img
                        v-if="!photoURL"
                        class="mx-auto"
                        max-height="145"
                        max-width="145"
                        :src="photoURL"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" md="8" class="pa-2 pr-3">
                    <p class="subtitle_header">
                      {{ caName }}
                    </p>
                  </v-col>
                  <v-btn
                    icon
                    class="edit-icon"
                    text
                    @click="showEditProfileModal = true"
                  >
                    <IconEdit />
                  </v-btn>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="12" class="pt-7 pb-2">
            <p class="subtitle_header">
              {{ $t('profile.admins.title') }}
            </p>

            <v-col cols="12" class="tables-view px-0">
              <v-tabs v-model="tab" :show-arrows="false" fixed-tabs>
                <v-tab href="#tab-1" class="menu_text">
                  {{ $t('profile.admins.tabs[0]') }} ({{
                    allAdminsTable.length
                  }})
                </v-tab>
                <!-- <v-tab href="#tab-2" class="menu_text"
                  >{{ $t('profile.admins.tabs[1]') }} ({{
                    mastersTable.length
                  }})
                </v-tab>
                <v-tab href="#tab-3" class="menu_text"
                  >{{ $t('profile.admins.tabs[2]') }} ({{
                    managersTable.length
                  }})
                </v-tab> -->
                <v-text-field
                  v-model="search"
                  class="search-table"
                  append-icon="mdi-magnify"
                  :label="$t('table.searchAdmin')"
                  single-line
                  hide-details
                />
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item value="tab-1">
                  <AdminTable
                    :search="search"
                    :headers="adminHeaders"
                    :items="allAdminsTable"
                    @add-new-admin="addNewAdmin"
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-col>
          <v-col cols="12" class="pt-5 pb-2">
            <p class="subtitle_header">
              {{ $t('profile.credentials.title') }}
            </p>
            <v-col cols="12" class="tables-view px-0 pt-0">
              <v-data-table
                v-if="templatesTable.length > 0"
                class="admin-table"
                :headers="templatesHeaders[$i18n.locale]"
                :items="templatesTable"
                :no-results-text="$t('table.noData')"
                :items-per-page="10"
                :hide-default-footer="templatesTable.length < 10"
              >
                <template #[`header.actions`]>
                  <v-menu bottom left :nudge-width="200" offset-y>
                    <template #activator="{ on }">
                      <v-btn
                        v-show="hasCredentials"
                        :ripple="false"
                        class="dot-menu"
                        icon
                        v-on="on"
                      >
                        <IconMenu />
                      </v-btn>
                    </template>

                    <slot name="actions">
                      <v-list :ripple="false" class="dot-menu">
                        <v-list-item :ripple="false" @click="addNewTemplate()">
                          <v-list-item-title class="menu-title">
                            {{
                              $t('profile.credentials.menu[0]')
                            }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </slot>
                  </v-menu>
                </template>
                <template #[`item.actions`]="{ item }">
                  <v-menu bottom left :nudge-width="200" offset-y>
                    <template #activator="{ on }">
                      <v-btn :ripple="false" class="dot-menu" icon v-on="on">
                        <IconMenu />
                      </v-btn>
                    </template>

                    <slot name="actions">
                      <v-list :ripple="false" class="dot-menu">
                        <v-list-item
                          :ripple="false"
                          @click="deleteTemplate(item)"
                        >
                          <v-list-item-title class="menu-title">
                            {{
                              $t('profile.credentials.menu[1]')
                            }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </slot>
                  </v-menu>
                </template>
              </v-data-table>

              <v-card v-else class="pa-8 dca-table">
                <v-img
                  class="mx-auto"
                  max-height="99"
                  max-width="130"
                  :src="emptyTablePhoto"
                />
                <v-card-text class="strong_text pa-0 pt-2">
                  <p
                    class="light_text text-center mb-0 mx-auto"
                    style="max-width: 450px"
                  >
                    {{ $t('profile.credentials.empty[0]') }}<br>
                    <a
                      class="links"
                      style="font-size: 13px"
                      @click="addNewTemplate()"
                    >
                      {{ $t('profile.credentials.empty[1]') }}
                    </a>
                    {{ $t('profile.credentials.empty[2]') }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="3" class="pb-8 counters">
        <v-row class="">
          <v-col cols="12" class="pt-2 pb-2 px-0">
            <v-card>
              <v-card-text class="strong_text">
                <IconTemplatesCredentials />
                <div>
                  <p>{{ totalTemplatesCounter }}/{{ allowedTemplates }}</p>
                  <span> {{ $t('dashboard.counters.templates') }} </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" class="pt-2 pb-2 px-0">
            <v-card>
              <v-card-text class="strong_text">
                <IconIssuedCredentials />
                <div>
                  <p>{{ allApprovedCounter }}/{{ allowedCredentials }}</p>
                  <span> {{ $t('dashboard.counters.issued') }} </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <EditProfileModal
      v-if="showEditProfileModal"
      @close="showEditProfileModal = false"
    />
  </v-container>
</template>

<style lang="scss">
.issuer-profile {
  .info-card {
    box-shadow: 0 2px 4px 0 var(--very-light-grey) !important;
  }
  #photo-container {
    position: relative;
    div.wrapper {
      max-width: 150px;
      height: 150px;
      border: solid 1px #eeeeee;
      border-radius: 50%;

      .company-header-avatar {
        width: 150px;
        height: 151px;
        border-radius: 50%;
        -webkit-background-clip: padding-box;
        -moz-background-clip: padding;
        background-clip: padding-box;
        float: left;
        background-size: contain;
        background-position: center center;
        background-color:  var(--teal-blue);
      }
      svg {
        position: absolute;
        margin-left: -148px;
      }
    }
    .v-image {
      margin: 0;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }
    svg {
      margin: 2px;
    }
  }
}

.edit-icon {
  margin-left: 15px;
  margin-top: -20px;
  &:hover {
    svg g {
      fill: var(--teal-blue);
    }
  }
}
</style>
